import React from "react";

export const Input = (props) => {
  const {
    name,
    placeholder,
    Icon = undefined,
    type = "text",
    inputMode,
    step,
    value,
    onChange,
    readOnly = false,
    units = "",
    align = "left",
    label = "",
    min = 0,
    onBlur = () => {},
    helperText = "",
    Helper = () => {
      return null;
    },
    ActionButton = () => {
      return null;
    },
  } = props;

  const textAlign = {
    center: "text-center",
    left: "text-left",
    right: "text-right",
  };

  return (
    <div className="w-full    focus-within:text-indigo-600 max-w-lg box-border mb-2 ">
      {!!label && (
        <label htmlFor={name} className="text-xs font-sans   text-justify pl-2">
          {label}
        </label>
      )}
      <div className="w-full flex justify-between items-center  bg-white hover:border-indigo-300 hover:focus-within:border-indigo-500  rounded-md  border focus-within:border-indigo-400 transition-all  ">
        {!!Icon && <Icon className="ml-2 min-w-fit" />}
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          autoComplete="off"
          value={value || ""}
          onChange={onChange}
          onFocus={(e) => e.target.select()}
          onPaste={(event) => {
            let selectionStart = event.target.selectionStart;
            let selectionEnd = event.target.selectionEnd;
            let pasteText = event.clipboardData.getData("text").trim();
            let initialValue = event.target.value;
            event.target.value =
              event.target.value.substring(0, selectionStart) + pasteText + event.target.value.substring(selectionEnd, initialValue.length);
            onChange(event);
            event.target.selectionStart = selectionStart;
            event.target.selectionEnd = selectionStart + pasteText.length;
            event.preventDefault();
          }}
          step={step}
          readOnly={readOnly}
          inputMode={inputMode}
          min={min}
          onBlur={onBlur}
          {...props.input}
          className={`w-full px-2 py-1 flex-grow text-sm focus:text-gray-900 ${textAlign[align]} bg-transparent outline-none`}
        />

        <span className="  flex items-center pr-2  cursor-pointer opacity-20 focus:opacity-100 hover:opacity-100">{units}</span>
        {ActionButton && (
          <span className="  flex items-center pr-2  cursor-pointer opacity-20 focus:opacity-100 hover:opacity-100">
            <ActionButton />
          </span>
        )}
      </div>
      {helperText && <span>{helperText}</span>}
      {!!Helper && <Helper />}
    </div>
  );
};
