import React from "react";
import { InputGroup } from "./InputGroup";

export const RadioGroup = ({ value, onChange, options, label, name }) => {
  return (
    <InputGroup label={label}>
      {options.map((o) => (
        <label
          key={o.value}
          className="text-sm select-none flex items-center cursor-pointer focus-within:border-indigo-500 border border-transparent py-0.5 px-1  rounded-md focus-within:bg-indigo-200 overflow-hidden transition-all hover:bg-indigo-100 hover:border-indigo-200"
        >
          <input
            type="radio"
            checked={value === o.value}
            name={name}
            value={o.value}
            className="outline-none mr-2 w-4 h-4 bg-indigo-700 bg-transparent"
            onChange={onChange}
          />
          {o.name}
        </label>
      ))}
    </InputGroup>
  );
};
