import React, { memo } from "react";
// import clsx from "clsx";
import { vibrate } from "./vibrate";
// import { vibrate } from "../../old_utils/vibrate";

const CountButton = ({ count = 0, changeCount = () => {}, min = 0, label = "", name, size, className, ...props }) => {
  const increment = (e) => {
    e.stopPropagation();
    changeCount(count + 1);
    vibrate([50]);
  };
  const decrement = (e) => {
    e.stopPropagation();
    if (count > min) {
      changeCount(count - 1);
      vibrate([50, 50, 50]);
    }
  };
  const onChange = (e) => {
    var val = parseInt(Number(e.target.value) >= min ? e.target.value : min) || 0;
    return changeCount(val);
  };
  return (
    <div
      className={`my-1 flex justify-center flex-col items-center  text-gray-500 text-sm focus-within:text-indigo-600 max-w-md box-border ${className}  `}
    >
      {!!label && (
        <label htmlFor={name} className="text-xs font-sans">
          {label}
        </label>
      )}
      <div className="flex flex-row w-20 h-8  rounded-md relative bg-gray-200 bg-opacity-50   text-center items-center border border-gray-300 overflow-hidden focus-within:border-indigo-500">
        <button
          type="button"
          onClick={decrement}
          className={`text-base w-6 font-thin select-none justify-center flex items-center  text-gray-600 hover:text-red-700 hover:bg-red-200  cursor-pointer  h-full `}
        >
          −
        </button>
        <input
          min={min}
          type="number"
          name="custom-input-number"
          className={`w-8 bg-white bg-opacity-60  text-center   font-semibold  hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700 h-full  border-l border-r outline-none py-2"
          `}
          onChange={onChange}
          onKeyPress={(e) => {
            if (
              e.key === "ArrowLeft" ||
              e.key === "ArrowRight" ||
              e.key === "ArrowUp" ||
              e.key === "ArrowDown" ||
              e.key === "Delete" ||
              e.key === "Backspace"
            ) {
              return;
            } else if (e.key.search(/\d/) === -1) {
              e.preventDefault();
            }
          }}
          onFocus={(e) => {
            e.target.select();
          }}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => (e.key === "Enter" ? e.target.blur() : null)}
          value={count?.toString()}
        ></input>
        <button
          type="button"
          onClick={increment}
          className={` text-base w-6 font-thin select-none  text-gray-600 hover:text-green-700 hover:bg-green-200  h-8 rounded-r cursor-pointer   flex justify-center items-center`}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default memo(CountButton);
