import React from "react";

export const InputGroup = ({ label = "", children, ...props }) => {
  return (
    <div {...props} className="rounded-lg border-gray-300 border pb-2 px-2 my-2">
      {!!label && <span className="text-base font-sans  text-gray-500 text-justify pl-2 m-4">{label}</span>}
      {children}
    </div>
  );
};
