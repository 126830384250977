import { Labels } from "./Labels";

function App() {
  return (
    <div className="">
      <Labels />
    </div>
  );
}

export default App;
