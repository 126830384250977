import { MdAddBox, MdCalendarToday, MdDateRange, MdEmojiPeople, MdPeopleAlt, MdSettings } from "react-icons/md";
import React, { useEffect, useReducer, useState } from "react";
// import CountButton from "../../../components/Inputs/CountButton";
import { Input } from "./Input";
import download from "downloadjs";
//   import AsyncSelect from "react-select/async";
import QRCode from "qrcode";

import { PDFDocument } from "pdf-lib";
// import { getFormattedDate } from "../../../old_utils/helperFunctions";
import { format } from "date-fns";
import cuid from "cuid";
import CountButton from "./CountButton";
import { getFormattedDate } from "./helperFunctions";
import { Checkbox } from "./Checkbox";
import { InputGroup } from "./InputGroup";
import { RadioGroup } from "./RadioGroup";
import { Button } from "./Button";

import { FaWarehouse } from "react-icons/fa";

import { MdClear, MdKeyboardArrowDown, MdKeyboardArrowRight, MdPrint } from "react-icons/md";
import { makeLabels } from "./makeLabelsReact";

const reducer = (state, { payload, type }) => {
  if (!type && payload.field && payload.option) {
    return { ...state, [payload.field]: payload.option };
  }

  switch (type) {
    case "INPUT_CHANGE":
      return {
        ...state,
        ...payload,
      };
    case "CONTAINER_UPDATE":
      return {
        ...state,
        units: state.units.includes(payload) ? state.units.filter((unit) => unit !== payload) : [...state.units, payload],
      };
    case "COMPANY_UPDATE":
      return {
        ...state,
        company: payload,
      };
    case "UPDATE_COUNT":
      return { ...state, ...payload };
    case "SHIPMENT_TYPE_UPDATE":
      return {
        ...state,
        shipmentType: payload,
        refNumber:
          state.refNumber.substr(0, state.refNumber.length - 2) + payload[0] + state.refNumber.substr(state.refNumber.length - 2 + 1),
      };
    case "UNKNOWN_UNITS":
      return { ...state, unknownTotalUnits: payload };
    case "CLEAR_FORM":
      return { ...initialState };
    case "TOGGLE_DISABLE_UNIT_NUMBERS":
      return { ...state, disableNumbers: payload };
    case "SEARCH_UPDATE":
      return { ...initialState, ...payload };

    default:
      return state;
  }
};

const initialState = {
  shipperName: "",
  date: format(new Date(), "MM/dd/yyyy"),
  refNumber: "",
  unitCount: 1,
  unknownTotalUnits: true,
  shipmentType: "SEA",
  disableNumbers: false,
  unitToStartWith: 1,
  labelsPerUnit: 4,
  existingUnits: 0,
};

const getPDF = async (state) => {
  download(
    await warehouseLabelFill(state),
    `WHL ${state.shipperName} ${state.refNumber} ${getFormattedDate(new Date())}.pdf`,
    "application/pdf"
  );
};
const getNewLabels = async (state) => {
  download(await makeLabels(state), `WHL ${state?.shipperName} ${state?.refNumber} ${getFormattedDate(new Date())}.pdf`, "application/pdf");
};

export const Labels = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [manualShipmentType, setManualShipmentType] = useState(false);
  const [manualStartNumber, setManualStartNumber] = useState(false);
  const [manualDate, setManualDate] = useState(false);
  const [isSFM, setIsSFM] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [showExistingUnits] = useState(false);

  const containerTypes = [
    { value: "Liftvan(s)", name: "Liftvans", Icon: MdAddBox },
    { value: "Pallet(s)", name: "Pallets", Icon: MdDateRange },
    { value: "Vault(s)", name: "Vaults", Icon: MdDateRange },
    { value: "Air", name: "Air Shipment", Icon: MdDateRange },
    { value: "SofaBox", name: "Sofa Box", Icon: MdDateRange },
  ];
  // const companyTypes = [
  //   { value: "PTS", name: "PTS", Icon: MdDateRange },
  //   { value: "SFM", name: "SFM", Icon: MdAddBox },
  // ];
  const shipmentType = [
    { value: "SEA", name: "Sea", Icon: MdAddBox },
    { value: "AIR", name: "Air", Icon: MdDateRange },
    { value: "ROAD", name: "Road", Icon: MdDateRange },
    { value: "PERM", name: "Perm Storage", Icon: MdDateRange },
    // { value: "DEST", name: "Destination", Icon: MdDateRange },
  ];

  // const onSubmit = (e) => {
  //   console.log("onsubmited");
  // };
  const getLabels = async (e) => {
    await getPDF({ ...state, isSFM });
  };

  const onChange = (e) => {
    dispatch({
      type: "INPUT_CHANGE",
      payload: { [e.target.name]: e.target.value },
    });
  };

  const changeCount = (field) => {
    dispatch({ type: "UPDATE_COUNT", payload: field });
  };

  const changeLabelsPerUnit = (count) => {
    changeCount({ labelsPerUnit: count });
  };
  const changeUnitToStartWith = (count) => {
    changeCount({ unitToStartWith: count });
  };
  const changeUnitCount = (count) => {
    changeCount({ unitCount: count });
  };

  const clearForm = (e) => {
    e.preventDefault();
    setManualShipmentType(false);
    setManualStartNumber(false);
    setManualDate(false);
    dispatch({ type: "CLEAR_FORM" });
  };
  const toggleDisableUnitNumbers = (e) => {
    dispatch({
      type: "TOGGLE_DISABLE_UNIT_NUMBERS",
      payload: e.target.checked,
    });
  };

  const parseRefNumber = (e) => {
    const v = e.target.value;
    const vLen = v.length;
    let type;

    if (vLen > 2) {
      type = v.charAt(vLen - 2).toLowerCase();
      switch (type) {
        case "a":
          return dispatch({ type: "SHIPMENT_TYPE_UPDATE", payload: "AIR" });
        case "p":
          return dispatch({ type: "SHIPMENT_TYPE_UPDATE", payload: "PERM" });
        case "r":
          return dispatch({ type: "SHIPMENT_TYPE_UPDATE", payload: "ROAD" });
        case "s":
          return dispatch({ type: "SHIPMENT_TYPE_UPDATE", payload: "SEA" });
        case "d":
          return dispatch({ type: "SHIPMENT_TYPE_UPDATE", payload: "DEST" });
        default:
          return console.log("not part of shipment");
      }
    }
  };

  const shipmentColor = (shipmentType) => {
    switch (shipmentType) {
      case "SEA":
        return "blue";
      case "AIR":
        return "green";
      case "ROAD":
        return "yellow";
      case "PERM":
        return "purple";
      case "DEST":
        return "red";

      default:
        return "gray";
    }
  };

  const toggleViewShipmentType = () => {
    setManualShipmentType((m) => !m);
  };

  const handleOnChange = (newValue) => {
    // const { fullName, objectID, refNumber } = newValue;
    // console.log(newValue);
    dispatch({ type: "SEARCH_UPDATE", payload: newValue });
    // dispatch({ type: "SEARCH_UPDATE", payload: { shipperName: fullName, jobId: objectID, refNumber: refNumber[0] } });

    // console.log(newValue);
  };

  useEffect(() => {
    if (state.refNumber.length === 12) {
      parseRefNumber({
        target: {
          value: state.refNumber,
        },
      });
    }
  }, [state.refNumber]);

  useEffect(() => {
    if (!!state.existingUnits) {
      setManualStartNumber(true);
      const unitCount = Number(state.existingUnits.length || (state.existingUnits && Object.keys(state.existingUnits).length) || 0);
      dispatch({
        type: "UPDATE_COUNT",
        payload: { unitToStartWith: unitCount + 1 },
      });
      // changeUnitToStartWith(unitCount + 1);
    }
  }, [state.existingUnits, dispatch]);

  return (
    <div
    //   IconTitle={FaWarehouse}
    //   title="Storage Labels"
    //
    >
      {settingsOpen && (
        <Settings
          state={state}
          changeLabelsPerUnit={changeLabelsPerUnit}
          toggleDisableUnitNumbers={toggleDisableUnitNumbers}
          setIsSFM={setIsSFM}
          isSFM={isSFM}
          setManualShipmentType={setManualShipmentType}
          dispatch={dispatch}
        />
      )}
      <div className="flex flex-col  justify-around bg-gray-100 p-1 rounded-lg m-1 h-full">
        {/* <div>{state.existingUnits && <pre>{JSON.stringify(state.existingUnits.length, 0, 2)}</pre>}</div> */}
        {/* <div className="flex flex-col ">
            Find customer
            <PTSJobsAutocomplete selected={state} setSelected={handleOnChange} />
          </div> */}
        <div className="bg-indigo-600 m-0.5 rounded px-4 py-1 text-white uppercase max-w-lg mx-auto flex w-96 justify-between items-center ">
          <h1>Warehouse Labels</h1>
          <button
            type="button"
            onClick={() => setSettingsOpen((v) => !v)}
            className="px-4 py-2 hover:bg-black hover:bg-opacity-10 transition-all rounded"
          >
            <MdSettings />
          </button>
        </div>
        <div className="flex">
          <form className="mb-6 px-6 w-96 mx-auto">
            {/* <Autocomplete
              // options={["Papaya", "Persimmon", "Paw Paw", "Prickly Pear", "Peach", "Pomegranate", "Pineapple"]}
            /> */}
            {/* {!isSFM && (
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadParamountJobsOptions}
                  onChange={handleOnChange}
                  defaultOptions
                  className="border-2 text-xl rounded-md max-w-md outline-none"
                  isClearable
                />
              )} */}
            <Input
              value={state.shipperName}
              name="shipperName"
              label="Customers Name"
              onChange={onChange}
              Icon={MdEmojiPeople}
              placeholder="Steve Rogers..."
              // readOnly={true}
            />
            {!isSFM && (
              <Input
                value={state.refNumber}
                placeholder="SE-XXXXXX-X1"
                name="refNumber"
                label="Reference Number"
                onChange={onChange}
                // readOnly={true}
                ActionButton={() => (
                  <button
                    type="button"
                    onClick={toggleViewShipmentType}
                    className={`bg-${shipmentColor(state.shipmentType)}-400 px-2 rounded-md`}
                  >
                    {state.shipmentType}
                  </button>
                )}
                // onBlur={parseRefNumber}
                Icon={() => <span className="font-bold mx-4">SE</span>}
                Helper={() => (
                  <div className="transition-all select-none text-xs text-gray-400 px-2 ">
                    Shipment type is detected from Reference number.
                    <br />
                    <span onClick={toggleViewShipmentType} className="text-indigo-300 hover:text-indigo-600 cursor-pointer">
                      Click here
                    </span>{" "}
                    to set manually.
                  </div>
                )}
              />
            )}
            {manualShipmentType && (
              <RadioGroup
                options={shipmentType}
                label="Shipment Type"
                onChange={(e) => {
                  // console.log(e.target.value);

                  dispatch({
                    type: "SHIPMENT_TYPE_UPDATE",
                    payload: e.target.value,
                  });
                }}
                value={state.shipmentType}
              />
            )}

            {/* <Input
              value={state.crew}
              name="crew"
              label="Containerizing Crew"
              onChange={onChange}
              Icon={MdPeopleAlt}
              placeholder="Name, Name, Name..."
            /> */}
            {!!state.existingUnits && (
              <InputGroup>
                <div
                  className="  rounded-md px-4 py-2 text-xl flex justify-between hover:bg-gray-200 cursor-pointer select-none"
                  // onClick={() => setShowExistingUnits((v) => !v)}
                >
                  <div>
                    Existing Units:{" "}
                    {Number(state.existingUnits.length || (state.existingUnits && Object.keys(state.existingUnits).length) || 0)}
                  </div>
                  {/* <pre>{JSON.stringify(state.existingUnits, null, 2)}</pre> */}
                  <button type="button" className="rounded-full hover:bg-gray-300 p-1 pl-2 text-center">
                    <MdPrint />

                    {showExistingUnits ? <MdKeyboardArrowDown className="mr-2" /> : <MdKeyboardArrowRight className="mr-2" />}
                  </button>
                </div>
                {showExistingUnits &&
                  state.existingUnits.map((unit) => (
                    <Checkbox
                      key={unit.id}
                      name={unit.id}
                      label={`${unit.number}`}
                      checked={state?.units.includes(unit.value)}
                      onChange={() => {
                        dispatch({ type: "unit_UPDATE", payload: unit.value });
                      }}
                      action={(e) => {
                        // console.log(e);
                      }}
                    />
                  ))}
              </InputGroup>
            )}

            <div className="flex justify-around items-center ">
              {/* <Title label="Units" /> */}
              <CountButton
                name="unitCount"
                type="number"
                count={state.unitCount}
                changeCount={changeUnitCount}
                min={1}
                label="Units to add"
              />
              {!manualStartNumber ? (
                <div
                  className="w-48 text-center text-xs pr-8 pt-4 text-gray-500 hover:text-blue-800 cursor-pointer"
                  onClick={() => setManualStartNumber(true)}
                >
                  Manually start off different number
                </div>
              ) : (
                <CountButton
                  min={1}
                  name="unitToStartWith"
                  type="number"
                  label="Starting Unit Number"
                  count={state.unitToStartWith}
                  changeCount={changeUnitToStartWith}
                />
              )}
            </div>
            {/* <Checkbox
              name="unknownTotalUnits"
              label="Total units not known yet"
              checked={state.unknownTotalUnits}
              onChange={() => {
                dispatch({
                  type: "UNKNOWN_UNITS",
                  payload: !state.unknownTotalUnits,
                });
              }}
            /> */}

            {/* <InputGroup label="Containers">
              {containerTypes.map((container) => (
                <Checkbox
                  key={container.value}
                  name={container.value}
                  label={container.name}
                  checked={state?.units.includes(container.value)}
                  onChange={() => {
                    dispatch({
                      type: "CONTAINER_UPDATE",
                      payload: container.value,
                    });
                  }}
                />
              ))}
            </InputGroup> */}

            {manualDate ? (
              <Input value={state.date} name="date" label="Date Containerized" onChange={onChange} Icon={MdCalendarToday} />
            ) : (
              <div
                onClick={() => setManualDate(true)}
                className="text-xs mt-2 text-center cursor-pointer text-gray-500 hover:text-blue-600  "
              >
                Date is set to Today ({state.date}). Set it manually.
              </div>
            )}
            <div className="flex justify-around mt-10">
              {state !== initialState && (
                <Button
                  Icon={MdClear}
                  tabIndex={-1}
                  onClick={(e) => {
                    setManualStartNumber(false);
                    clearForm(e);
                  }}
                  color="gray"
                >
                  Clear
                </Button>
              )}
              {/* <Button onClick={getLabels}>Get LABELS!</Button> */}
              <Button onClick={() => getNewLabels(state)}>Get new LABELS!</Button>
            </div>
          </form>
        </div>
        {/* <div className="flex mx-auto text-xs">
            Container Details
            <div>
              <pre>{JSON.stringify(state, null, 2)}</pre>
            </div>
          </div> */}
      </div>
    </div>
  );
};

export async function warehouseLabelFill(data) {
  const {
    shipperName,
    date,
    refNumber,
    crew,
    units,
    unknownTotalUnits,
    unitCount,
    shipmentType,
    unitToStartWith = 1,
    labelsPerUnit = 2,
    disableNumbers = false,
    id,
    isSFM,
  } = data;

  console.log(data);

  let newUnits = [];

  for (let index = 0; index < unitCount; index++) {
    newUnits[index] = {
      id: cuid(),
      number: unitToStartWith > 1 ? index + unitToStartWith : index + 1,
    };
  }

  // if (!isSFM)
  //   addWarehouseUnitsoFirestore({
  //     shipperName,
  //     refNumber,
  //     crew,
  //     shipmentType,
  //     id,
  //     newUnits,
  //   });
  const pdf = !isSFM ? "pdf/NEW LABELS Form.pdf" : "pdf/NEW LABELS Form - SFM.pdf";
  const baseUrl = window.location.origin.toString() + process.env.PUBLIC_URL + "/";
  const formUrl = baseUrl + pdf;
  const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(formPdfBytes);
  const form = pdfDoc.getForm();

  const mergeDoc = await PDFDocument.create();
  setField("shipperFullName", shipperName, form);
  setField("referenceNumber", refNumber, form);
  setField("crew", crew, form);
  setField("date", date, form);
  setField("unitTypes", units, form);
  setField("shipmentType", shipmentType, form);
  form.flatten();

  let unitToStart = unitToStartWith > 1 ? unitToStartWith : 1;

  for (let index = 0; index < unitCount; index++) {
    console.log("WHAT IS ID");
    console.log({ ...newUnits[index] });
    const unitId = newUnits[index].id;
    const tempDoc = await PDFDocument.create();

    const coppiedPages = await tempDoc.copyPages(pdfDoc, pdfDoc.getPageIndices());
    coppiedPages.forEach((page) => {
      tempDoc.addPage(page);
    });
    // const tempForm = tempDoc.getForm();
    if (!disableNumbers) {
      const tempPages = tempDoc.getPages();
      tempPages[0].drawText(
        `${index + unitToStart < 10 ? " " : ""}${index + unitToStart}${unknownTotalUnits ? "" : `/${unitCount + unitToStart - 1}`}`,
        {
          x: 528,
          y: 165 - 126 + 25,
          size: 96,
        }
      );
      // await tempDoc.save();
      // const qrCode = await generateQR(`sfmtools.com/w/unit/${unitId}`);

      // await setQRCode(qrCode, 320, 20, 160, 160, tempDoc, tempPages[0]);
    }

    const coppiedTempPages = await mergeDoc.copyPages(tempDoc, tempDoc.getPageIndices());
    for (let index = 0; index < labelsPerUnit; index++) {
      coppiedTempPages.forEach((page) => mergeDoc.addPage(page));
    }
  }
  return await mergeDoc.save();
}

const generateQR = async (text) => {
  try {
    return await QRCode.toDataURL(text, { errorCorrectionLevel: "H" });
  } catch (err) {
    console.error(err);
  }
};

const setField = (pdfField, value, form, condition = false) => {
  if (!pdfField || !value || condition) return;
  if (Array.isArray(value)) value = value.join(", ");
  return form.getTextField(pdfField).setText(value.toString());
};

const setQRCode = async (qrCode, x, y, width = 25, height = 10, pdfDoc, page) => {
  if (!qrCode || !x || !y) return;
  const bytes = await fetch(qrCode).then((res) => res.arrayBuffer());
  const image = await pdfDoc.embedPng(bytes);
  return page.drawImage(image, { x, y, height, width });
};

const Settings = ({ state, changeLabelsPerUnit, toggleDisableUnitNumbers, setIsSFM, isSFM, setManualShipmentType, dispatch }) => (
  <div className="bg-gray-100 m-1 flex flex-col items-center justify-around md:flex-row   rounded-md transition-all duration-200 py-6">
    <div>
      <CountButton
        label="Labels per unit"
        name="labelsPerUnit"
        type="number"
        count={state.labelsPerUnit}
        changeCount={changeLabelsPerUnit}
        min={1}
      />
    </div>
    <div>
      <Checkbox
        name="disableNumbers"
        label="Disable unit number & QR codes"
        checked={state.disableNumbers}
        onChange={toggleDisableUnitNumbers}
      />
    </div>
    {/* <div>
      <Checkbox
        name="isSFM"
        label="Super Friends Moving Storage?"
        checked={isSFM}
        onChange={() =>
          setIsSFM((v) => {
            if (!v) {
              setManualShipmentType(true);
              dispatch({ type: "SHIPMENT_TYPE_UPDATE", payload: "PERM" });
            }
            return !v;
          })
        }
      />
    </div> */}
  </div>
);
