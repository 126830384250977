import React from "react";
import { MdOutlineNote } from "react-icons/md";
// import CountButton from "./CountButton";

export const Checkbox = ({
  checked = false,
  name,
  onChange,
  label,
  value,
  action,
  size,
  actionIcon = "print",
  ActionIcon = null,
  actionButtonDisabled = false,
  displayIcon,
  DisplayIcon = null,
}) => {
  const toggleClick = (e) => {
    e.stopPropagation();
    action(name);
  };

  // const changeCount = (newCount) => {
  //   //if not checked check
  //   if (!checked) onChange();
  //   onCountButtonChange(newCount);
  // };

  return (
    <label
      className={` justify-between ${
        size === "small" ? "text-xs" : "text-sm"
      } select-none flex items-center cursor-pointer focus-within:border-indigo-500 border border-transparent p-0.5  rounded-md focus-within:bg-indigo-200 overflow-hidden transition-all hover:bg-indigo-100 hover:border-indigo-200 
    ${checked ? "font-bold bg-blue-100 " : ""}`}
    >
      <input
        checked={checked}
        type="checkbox"
        name={name}
        className="outline-none  mx-2 w-4 h-4 bg-indigo-700 bg-transparent flex-none"
        onChange={onChange}
        value={value}
      />
      <span className="flex-grow w-full">{label}</span>
      {!!action && (
        <button
          onClick={toggleClick}
          type="button"
          disabled={actionButtonDisabled}
          className="rounded-full bg-black bg-opacity-5 p-2 justify-center flex items-center transition-all hover:bg-opacity-25"
        >
          {ActionIcon ? <ActionIcon /> : <MdOutlineNote />}
          {/* <span className="text-xs">Print</span> */}
        </button>
      )}
      {DisplayIcon && <DisplayIcon />}
      {/* <CountButton size="small" changeCount={changeCount} count={count} /> */}
    </label>
  );
};
