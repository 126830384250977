//react version
import cuid from "cuid";
import { PDFDocument, rgb, TextAlignment } from "pdf-lib";
import QRCode from "qrcode";

const today = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return mm + "/" + dd + "/" + yyyy;
};

const datax = {
  units: { unitIdxzvxvcxv: { number: 1 }, egergwersrsdr: { number: 2 }, gsersdfgsdfhh32: { number: 21 } },
  shipperName: "Arnas Jelizarovas",
  refNumber: "SE-470076-R1",
  date: today(),
};

const paramountCompany = {
  name: "Paramount Transportation Systems",
  address: "1105 Andover Park W, Tukwila, WA 98188",
  logo: "images/pts_logo_wob_name_4x.png",
};

export async function makeLabels(data = datax, company = paramountCompany) {
  const { shipperName, refNumber, date, disableNumbers, labelsPerUnit = 4 } = data;
  const pdfDoc = await PDFDocument.create();
  const form = pdfDoc.getForm();
  const imgUrl = "/images/pts_logo_wob_name_4x.png";
  const imgBytes = await fetch(imgUrl).then((res) => res.arrayBuffer());
  const img = await pdfDoc.embedPng(imgBytes);
  const { width, height } = img.scale(1);
  const ratio = width / height;
  let newHeight = 128;

  const shipperNameField = form.createTextField("shipperName");
  shipperNameField.setText(shipperName);
  shipperNameField.setAlignment(TextAlignment.Center);
  const refNumberField = form.createTextField("refNumber");
  refNumberField.setText(refNumber);
  refNumberField.setAlignment(TextAlignment.Center);

  const shipmentTypeField = form.createTextField("shipmentType");
  const [shipmentTypeLabel = "", shipmentTypeBackground = "#aaa"] = parseShipmentType(refNumber.trim());
  shipmentTypeField.setText(shipmentTypeLabel);
  shipmentTypeField.setAlignment(TextAlignment.Center);

  const dateField = form.createTextField("dateField");
  dateField.setText(date);
  dateField.setAlignment(TextAlignment.Center);

  const companyName = form.createTextField("companyName");
  companyName.setText(company.name);
  companyName.setAlignment(TextAlignment.Center);
  const companyAddress = form.createTextField("companyAddress");
  companyAddress.setText(company.address);
  companyAddress.setAlignment(TextAlignment.Center);
  let units = data.units;
  if (!units) {
    let arr = [];
    for (let i = 0; i < data.unitCount; i++) {
      arr[i] = cuid();
    }
    units = arr.reduce((a, unitId, index) => {
      return {
        ...a,
        [`${unitId}`]: { number: data.unitToStartWith + index },
      };
    }, {});
  }

  const sortedUnits = Object.keys(units).sort(function (a, b) {
    return units[a].number - units[b].number;
  });

  for (let unitId of sortedUnits) {
    const number = units[unitId].number;
    const numberField = form.createTextField(`number-${number}-${cuid.slug()}`);
    numberField.setText(`${number}`);
    numberField.setAlignment(TextAlignment.Right);
    const unitIdField = form.createTextField(`unitId-${number}-${cuid.slug()}`);
    unitIdField.setText(`sfmtools.com/w/unit/${unitId}`);
    unitIdField.setAlignment(TextAlignment.Center);

    const qrCode = await generateQR(`sfmtools.com/w/unit/${unitId}`);
    const qrImage = await pdfDoc.embedPng(qrCode);

    for (let index = 0; index < labelsPerUnit; index++) {
      const page = pdfDoc.addPage([792, 612] /*[612, 792]*/);
      page.drawImage(img, {
        x: 50,
        y: 445,
        width: newHeight * ratio,
        height: newHeight,
      });

      shipmentTypeField.addToPage(page, {
        x: 492,
        y: 445,
        width: 792 - 492 - 50,
        height: 573 - 445,
        textColor: pdfLibColorParse("#ffffff"),
        backgroundColor: pdfLibColorParse(shipmentTypeBackground), // rgb(0.15, 0.9, 0.2),
        borderWidth: 0,
      });

      shipperNameField.addToPage(page, {
        x: 50,
        y: 280,
        width: 692,
        height: 110,
        borderWidth: 0,
      });
      refNumberField.addToPage(page, {
        x: 50,
        y: 210,
        width: 692,
        height: 70,
        textColor: pdfLibColorParse("#000000"),
        borderWidth: 0,
      });

      dateField.addToPage(page, {
        x: 50,
        y: 30,
        width: 200,
        height: 60,
        color: rgb(1, 1, 1),
        borderWidth: 0,
      });
      companyName.addToPage(page, {
        x: 50,
        y: 420,
        width: 200,
        height: 20,
        color: rgb(1, 1, 1),
        borderWidth: 0,
      });
      companyAddress.addToPage(page, {
        x: 50,
        y: 405,
        width: 200,
        height: 15,
        color: rgb(1, 1, 1),
        borderWidth: 0,
      });
      if (!disableNumbers) {
        numberField.addToPage(page, {
          x: 492,
          y: 30,
          width: 250,
          height: 160,
          color: rgb(1, 1, 1),
          borderWidth: 0,
        });

        page.drawImage(qrImage, {
          x: 310,
          y: 25,
          width: 180,
          height: 180,
        });

        unitIdField.addToPage(page, {
          x: 310,
          y: 30,
          width: 180,
          height: 10,
          borderWidth: 0,
        });
      }
    }
  }
  form.flatten();
  return await pdfDoc.save();
}

function pdfLibColorParse(colorHex) {
  const { r, g, b } = hexToRgb(colorHex);
  //TODO error handling (will fail with none hex string)
  return rgb(r, g, b);
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16) / 255,
        g: parseInt(result[2], 16) / 255,
        b: parseInt(result[3], 16) / 255,
      }
    : null;
}

function parseShipmentType(refNumber) {
  //   console.log(refNumber);
  let sT = "";
  if (!refNumber || !typeof refNumber === "string") return null;
  if (!refNumber.trim().startsWith("SE-")) return null;
  const refLength = refNumber.length;
  let type;

  if (refLength > 2) {
    type = refNumber.charAt(refLength - 2).toLowerCase();
    switch (type) {
      case "a":
        sT = "air";
        break;
      case "p":
        sT = "permanentStorage";
        break;
      case "r":
        sT = "road";
        break;
      case "s":
        sT = "sea";
        break;
      default:
        sT = "unkown";
    }
  }
  return [shipmentTypes[sT].label, shipmentTypes[sT].background, shipmentTypes[sT].color];
}

const shipmentTypes = {
  air: { background: "#e53935", color: "#ffffff", label: "AIR" },
  sea: { background: "#1e88e5", color: "#ffffff", label: "SEA" },
  road: { background: "#43a047", color: "#ffffff", label: "ROAD" },
  permanentStorage: { background: "#f9a825", color: "#ffffff", label: "PERM" },
  unkown: { background: "#212121", color: "#ffffff", label: "UNK" },
};

const generateQR = async (text) => {
  try {
    return await QRCode.toDataURL(text, { errorCorrectionLevel: "H" });
  } catch (err) {
    console.error(err);
  }
};
